var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "fill-height", attrs: { outlined: "", tile: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      "offset-y": "",
                      outlined: "",
                      label: "チャートの種類を選ぶ",
                      items: _vm.chartTypes,
                      "item-value": "value",
                      "item-text": "text",
                    },
                    on: { change: _vm.callDuct },
                    model: {
                      value: _vm.form.chartType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "chartType", $$v)
                      },
                      expression: "form.chartType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      disabled: !(_vm.form.chartType !== null),
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      label: "棒グラフデータの種類を選ぶ",
                      items: _vm.dataTypes,
                      "item-value": "value",
                      "item-text": "text",
                    },
                    model: {
                      value: _vm.form.dataType[0],
                      callback: function ($$v) {
                        _vm.$set(_vm.form.dataType, 0, $$v)
                      },
                      expression: "form.dataType[0]",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-slide-y-transition",
            { attrs: { group: "" } },
            [
              _vm.form.chartType === "time-series"
                ? _c(
                    "v-row",
                    { key: "select-line-chart" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              label: "折線グラフデータの種類を選ぶ (optional)",
                              items: _vm.dataTypes,
                              "item-value": "value",
                              "item-text": "text",
                            },
                            model: {
                              value: _vm.form.dataType[1],
                              callback: function ($$v) {
                                _vm.$set(_vm.form.dataType, 1, $$v)
                              },
                              expression: "form.dataType[1]",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.chartType === "time-series"
                ? _c(
                    "v-row",
                    { key: "select-classif" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              label: "棒グラフデータの分類方法を選ぶ",
                              items: _vm.classifTypes,
                              "item-value": "value",
                              "item-text": "text",
                            },
                            model: {
                              value: _vm.form.classifType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "classifType", $$v)
                              },
                              expression: "form.classifType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.chartType === "ind-prod"
                ? _c(
                    "v-row",
                    { key: "autocomplete-product" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              disabled: _vm.autocompleteStates.disabled,
                              filter: _vm.filterProduct,
                              label: _vm.autocompleteStates.label,
                              items: _vm.productList,
                              "item-value": "product_code",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function ({
                                    item,
                                    select,
                                    selected,
                                    attrs,
                                  }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              "input-value": selected,
                                            },
                                            on: { click: select },
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.name +
                                                `(${item.product_code})`
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                item.name +
                                                  ` (${item.product_code})`
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2861762309
                            ),
                            model: {
                              value: _vm.form.product.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.product, "code", $$v)
                              },
                              expression: "form.product.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.chartType === "ind-prod" &&
              _vm.form.dataType[0] === "shipments"
                ? _c(
                    "v-row",
                    { key: "select-forecast" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              "deletable-chips": "",
                              dense: "",
                              "hide-details": "",
                              multiple: "",
                              outlined: "",
                              "small-chips": "",
                              label: "比較する予測値を選ぶ",
                              items: _vm.forecastTypes,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function ({
                                    attrs,
                                    item,
                                    parent,
                                    selected,
                                  }) {
                                    return [
                                      item === Object(item)
                                        ? _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "grey darken-1",
                                                  "input-value": selected,
                                                  label: "",
                                                  small: "",
                                                  dark: "",
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pr-2 chip-label",
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return parent.selectItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-close ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2836573797
                            ),
                            model: {
                              value: _vm.form.forecastType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "forecastType", $$v)
                              },
                              expression: "form.forecastType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.chartType === "ranking"
                ? _c(
                    "v-row",
                    { key: "checkbox-isDeviation" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              label: "差分を見る",
                            },
                            model: {
                              value: _vm.form.isDeviation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isDeviation", $$v)
                              },
                              expression: "form.isDeviation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { key: "radio-isRel" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "pt-0",
                          attrs: { "hide-details": "" },
                          model: {
                            value: _vm.form.isRel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isRel", $$v)
                            },
                            expression: "form.isRel",
                          },
                        },
                        _vm._l(_vm.radioChoices, function (choice, idx) {
                          return _c("v-radio", {
                            key: `radio_${idx}`,
                            attrs: { label: choice.text, value: choice.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.chartType === "ranking" && !_vm.form.isRel
                ? _c(
                    "v-row",
                    { key: "chip-ranking-yw" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("span", [_vm._v("描画する週を選ぶ: ")]),
                          _c("chip-menu", {
                            attrs: {
                              "list-items": _vm.years,
                              "chip-text": `${String(
                                _vm.form.absTimeRange[1].year
                              )}年`,
                              "chip-props": {
                                small: true,
                                outlined: true,
                                label: true,
                              },
                              "chip-class": "mr-1",
                            },
                            on: {
                              "click:list": function ($event) {
                                _vm.form.absTimeRange[1].year = $event
                              },
                            },
                          }),
                          _c("chip-menu", {
                            attrs: {
                              "list-items": _vm.weeks,
                              "chip-text": `第${String(
                                _vm.form.absTimeRange[1].week
                              )}週`,
                              "chip-props": {
                                small: true,
                                outlined: true,
                                label: true,
                              },
                            },
                            on: {
                              "click:list": function ($event) {
                                _vm.form.absTimeRange[1].week = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              ![null, "ranking"].includes(_vm.form.chartType) && !_vm.form.isRel
                ? _c("yw-range-menu", {
                    key: "ywrange-exceptranking-week-nonRel",
                    attrs: {
                      "acceptable-year": _vm.years,
                      "initial-yw-range": _vm.form.absTimeRange,
                      "chip-props": {
                        small: true,
                        outlined: true,
                        label: true,
                      },
                      "row-class": { "mt-6": true },
                    },
                    on: { click: _vm.getYwRange },
                  })
                : _vm._e(),
              ![null, "ranking"].includes(_vm.form.chartType) && _vm.form.isRel
                ? _c(
                    "v-row",
                    { key: "chip-exceptranking-week-rel" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("chip-menu", {
                            attrs: {
                              "list-items": _vm.weeks,
                              "chip-text": String(_vm.form.relTimeRange.range),
                              "chip-props": {
                                small: true,
                                outlined: true,
                                label: true,
                              },
                              "chip-class": "mr-1",
                            },
                            on: {
                              "click:list": function ($event) {
                                _vm.form.relTimeRange.range = $event
                              },
                            },
                          }),
                          _c("span", [_vm._v("週間前から現在まで")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }