var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-3 ma-10",
      attrs: { tile: "", "min-height": "500", loading: _vm.loading },
    },
    [
      _c(
        "v-card-title",
        [
          _vm._v(" ダッシュボード "),
          _c(
            "v-slide-x-transition",
            [
              _vm.editMode
                ? _c(
                    "v-chip",
                    { attrs: { small: "", color: "yellow lighten-1" } },
                    [_vm._v("編集モード ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.editMode ? "primary" : "grey lighten-2" },
              on: { click: _vm.changeEditModeState },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v(
                  _vm._s(_vm.editMode ? "mdi-content-save-all" : "mdi-wrench")
                ),
              ]),
              _vm._v(_vm._s(_vm.editMode ? "保存する" : "編集する") + " "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-slide-y-transition",
        [
          _vm.editMode
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [_vm._v(" チャートをドラッグして配置を変更できます。 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "draggable",
        {
          attrs: { tag: "v-row", disabled: !_vm.editMode, animation: "400" },
          model: {
            value: _vm.registeredCharts,
            callback: function ($$v) {
              _vm.registeredCharts = $$v
            },
            expression: "registeredCharts",
          },
        },
        [
          _vm._l(_vm.registeredCharts, function (chart, idx) {
            return _c(
              "v-col",
              {
                key: chart.id,
                attrs: { cols: "12", md: "6", lg: "6", xl: "4" },
              },
              [
                _c(
                  chart.chartComponent,
                  _vm._b(
                    {
                      ref: "chart",
                      refInFor: true,
                      tag: "component",
                      on: {
                        "click:close": function ($event) {
                          return _vm.deleteChart(idx)
                        },
                      },
                    },
                    "component",
                    {
                      ...chart.props,
                      ...{ duct: _vm.duct },
                    },
                    false
                  )
                ),
              ],
              1
            )
          }),
          _vm.editMode
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6", xl: "4" } },
                [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "fill-height d-flex align-center",
                                  attrs: {
                                    "min-height": "450",
                                    outlined: "",
                                    elevation: !_vm.editMode
                                      ? 2
                                      : hover
                                      ? 20
                                      : 5,
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex justify-center",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v("mdi-view-grid-plus")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-fade-transition",
                                    [
                                      _c(
                                        "v-overlay",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: hover,
                                              expression: "hover",
                                            },
                                          ],
                                          attrs: { absolute: "" },
                                        },
                                        [
                                          _c("edit-dialog", {
                                            attrs: {
                                              duct: _vm.duct,
                                              "current-yw": _vm.currentYw,
                                            },
                                            on: {
                                              "click:confirm": _vm.addNewChart,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2947963475
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, timeout: "3000" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "pink", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar.isShown = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("Close ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar.isShown,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "isShown", $$v)
            },
            expression: "snackbar.isShown",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }