var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "grey lighten-3" }, [
        _vm._v("チャートを追加する"),
      ]),
      _c(
        "v-card-text",
        { staticClass: "pt-6 pb-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "7" } },
                [
                  _c("chart-preview", {
                    ref: "preview",
                    attrs: {
                      "chart-component": _vm.chartComponent,
                      "chart-props": {
                        duct: _vm.duct,
                        ..._vm.chartProps,
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "5" } },
                [
                  _c("chart-setting", {
                    attrs: { duct: _vm.duct },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "error" },
              on: { click: _vm.onClickCancel },
            },
            [_vm._v("キャンセル ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "primary",
                disabled: _vm.confirmDisabled,
              },
              on: { click: _vm.onClickConfirm },
            },
            [_vm._v("確定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }