var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "fill-height d-flex align-center",
      attrs: { outlined: "", tile: "", height: "500" },
    },
    [
      _c(
        "v-card-text",
        [
          _vm.chartShown
            ? _c(
                _vm.chartComponent,
                _vm._b(
                  { key: _vm.componentKey, tag: "component" },
                  "component",
                  _vm.chartProps,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }